import { useRef } from 'react';

const getCoordinates = (mousePosition, boundingRefRect, factor) => ({
    x: -(mousePosition.clientX - boundingRefRect.left) / factor,
    y: -(mousePosition.clientY - boundingRefRect.top) / factor,
});

// eslint-disable-next-line import/prefer-default-export
export const useParallax = (factor) => {
    const componentRef = useRef();

    return [
        componentRef,
        (event) => {
            if (componentRef.current) {
                const element = componentRef.current;
                const { x, y } = getCoordinates(event, element.getBoundingClientRect(), factor);

                element.style.transform = `translate(${x}px, ${y}px)`;
            }
        },
    ];
};
