import React from 'react';
import Template from '@clearscore/shared.website-template';
import Text from '@clearscore/ui.rainbow.text';
import Button from '@clearscore/ui.rainbow.button';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { PAGE_NAMESPACE } from './lib/constants';
import { useParallax } from './lib/use-parallax';
import RingOne from './assets/Ring1.svg';
import RingTwo from './assets/Ring2.svg';
import RingThree from './assets/Ring3.svg';
import RingFour from './assets/Ring4.svg';
import RingFive from './assets/Ring5.svg';
import RingSix from './assets/Ring6.svg';
import RingSeven from './assets/Ring7.svg';
import styles from './not-found-page.module.css';

const NotFoundPage = ({ appStoreConfig, cookiePolicyConfig, homePageUrl }) => {
    // to prevent: error TS2589: Type instantiation is excessively deep and possibly infinite.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { t } = useTranslation(PAGE_NAMESPACE);

    const [layerOneRef, updateLayerOne] = useParallax(4);
    const [layerTwoRef, updateLayerTwo] = useParallax(40);
    const [layerThreeRef, updateLayerThree] = useParallax(20);
    const [layerMiddleRingRef, updateLayerMiddle] = useParallax(100);

    const handleMouseMove = (e) => {
        e.preventDefault();
        updateLayerOne(e);
        updateLayerTwo(e);
        updateLayerThree(e);
        updateLayerMiddle(e);
    };

    return (
        <Template pullUpContent appStoreConfig={appStoreConfig} cookiePolicyConfig={cookiePolicyConfig}>
            <div className={styles.container} onMouseMove={handleMouseMove}>
                <div className={styles.ringsWrapper}>
                    <div className={styles.layerContainer} ref={layerOneRef}>
                        <span className={cx(styles.allRings, styles.ringOne)}>
                            <RingOne />
                        </span>
                        <span className={cx(styles.allRings, styles.ringTwo)}>
                            <RingTwo />
                        </span>
                    </div>

                    <div className={styles.layerContainer} ref={layerTwoRef}>
                        <span className={cx(styles.allRings, styles.ringThree)}>
                            <RingThree />
                        </span>
                        <span className={cx(styles.allRings, styles.ringFour)}>
                            <RingFour />
                        </span>
                    </div>

                    <div className={styles.layerContainer} ref={layerThreeRef}>
                        <span className={cx(styles.allRings, styles.ringFive)}>
                            <RingFive />
                        </span>
                        <span className={cx(styles.allRings, styles.ringSix)}>
                            <RingSix />
                        </span>
                    </div>
                </div>

                <div className={styles.wrapper}>
                    <div className={styles.donut}>
                        <Text type={Text.types.JUMBO} weight={Text.weights.BOOK}>
                            <span className={cx(styles.first, styles.donutText)}>4</span>
                            <span className={cx(styles.second, styles.zero, styles.donutText)}>
                                0
                                <span className={styles.ringSeven} ref={layerMiddleRingRef}>
                                    <RingSeven />
                                </span>
                            </span>
                            <span className={cx(styles.third, styles.donutText)}>4</span>
                        </Text>
                    </div>
                    <div className={styles.copy}>
                        <Text.H2 tag={Text.tags.H1}>{t('copy')}</Text.H2>
                    </div>
                    <div className={styles.button}>
                        <Button
                            type={Button.types.PRIMARY}
                            size={Button.sizes.LARGE}
                            theme={Button.themes.DARK}
                            href={homePageUrl}
                        >
                            {t('cta')}
                        </Button>
                    </div>
                </div>
            </div>
        </Template>
    );
};

export default NotFoundPage;
